:root,
:root * {
  scrollbar-color: #c2c2c2 #f0f0f0;
  scrollbar-width: thin;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  src: url('assets/fonts/HelveticaNeue-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  src: url('assets/fonts/HelveticaNeue-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  src: url('assets/fonts/HelveticaNeue-Bold.otf') format('opentype');
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e9e9ee;
  border-radius: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb:horizontal:hover,
*::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(0, 0, 0, 0.27);
}

*::-webkit-scrollbar-thumb:horizontal:active,
*::-webkit-scrollbar-thumb:vertical:active {
  background-color: rgba(0, 0, 0, 0.54);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
* {
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.autocomplete-dropdown-container {
  position: relative;
  z-index: 10;
}

div.MuiPopover-paper {
  border-radius: 10px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9e9ee;
}

.suggestion-item,
.suggestion-item--active {
  padding: 5px 10px;
  color: rgb(33, 33, 33);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  min-width: 200px;
}

.recharts-tooltip-wrapper {
  outline: none;
}

.MuiPaper-rounded {
  border-radius: 16px !important;
  box-shadow: none !important;
}
